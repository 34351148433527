// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../../public/icons/selector.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../../../../public/icons/instrumental-panel-selector.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../../../../public/icons/nwse-resize.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../../../../../public/icons/move.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../../../../../../public/icons/pen.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../../../../../../public/icons/marker.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../../../../../../../public/icons/eraser.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../../../../../../../public/icons/note-cursor.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../../../../../../../public/icons/text.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor-selector {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.cursor-selector-scalable {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.cursor-selector-resize {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  height: 12px !important;
  width: 12px !important;
}
.cursor-selector-move {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
.cursor-pen {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
.cursor-marker {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
.cursor-eraser {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
.cursor-notes {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}
.cursor-notes-text {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}
.cursor-mover {
  cursor: pointer;
}
.cursor-pen, .cursor-eraser, .cursor-marker, .cursor-selector, .cursor-mover, .cursor-selector-resize, .cursor-selector-scalable, .cursor-selector-move, .cursor-notes, .cursor-notes-text {
  height: 30px;
  width: 30px;
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/pages/BoardPage/components/WhiteBoard/components/Cursor/Cursor.scss"],"names":[],"mappings":"AACI;EACI,mDAAA;AAAR;AAEQ;EACI,mDAAA;AAAZ;AAGQ;EACI,mDAAA;EACA,uBAAA;EACA,sBAAA;AADZ;AAIQ;EACI,mDAAA;AAFZ;AAKI;EACI,mDAAA;AAHR;AAKI;EACI,mDAAA;AAHR;AAKI;EACI,mDAAA;AAHR;AAKI;EACI,mDAAA;AAHR;AAKQ;EACI,mDAAA;AAHZ;AAMI;EACI,eAAA;AAJR;AAMI;EACI,YAAA;EACA,WAAA;EACA,wBAAA;EACA,kBAAA;EACA,4BAAA;AAJR","sourcesContent":[".cursor {  \n    &-selector {\n        background: url('../../../../../../../public/icons/selector.svg');\n        \n        &-scalable {\n            background: url('../../../../../../../public/icons/instrumental-panel-selector.svg');\n        }\n\n        &-resize {\n            background: url('../../../../../../../public/icons/nwse-resize.svg');\n            height: 12px !important;\n            width: 12px !important;\n        }\n\n        &-move {\n            background: url('../../../../../../../public/icons/move.svg');\n        }\n    }\n    &-pen {\n        background: url('../../../../../../../public/icons/pen.svg');\n    }\n    &-marker {\n        background: url('../../../../../../../public/icons/marker.svg');\n    }\n    &-eraser {\n        background: url('../../../../../../../public/icons/eraser.svg');\n    }\n    &-notes {\n        background: url('../../../../../../../public/icons/note-cursor.svg');\n\n        &-text {\n            background: url('../../../../../../../public/icons/text.svg');\n        }\n    }\n    &-mover {\n        cursor: pointer;\n    }\n    &-pen, &-eraser, &-marker, &-selector, &-mover, &-selector-resize, &-selector-scalable, &-selector-move, &-notes, &-notes-text {\n        height: 30px;\n        width: 30px;\n        background-size: contain;\n        position: absolute;  \n        background-repeat: no-repeat;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
