import { useState } from 'react'
import './InstrumentPanel.scss'
import ConfirmBoardClearingModal from '../ConfirmBoardClearingModal/ConfirmBoardClearingModal'
import { useParams } from 'react-router-dom'
import InfoRoomModal from '../../../InfoRoomModal/InfoRoomModal'
import colors from '../colors'
import jsonParse from '../jsonParse'
import api from '../../../../../../shared/api'
import authHelper from '../../../../../../utils/authHelper'

const InstrumentPanel = ({
    instrument, setInstrument, 
    setElements, initAudioState, initVideoState,
    penColorIndex, setPenColorIndex,
    markerColorIndex, setMarkerColorIndex,
    setUsers
    }) => {
    const [audioWorks, setAudioWorks] = useState(initAudioState)
    const [videoWorks, setVideoWorks] = useState(initVideoState)
    const [screenWorks, setScreenWorks] = useState(false)
    const [openInfoRoomModal, setInfoRoomModalOpen] = useState(false)
    const [confirmBoardClearingModalOpen, setConfirmBoardClearingModalOpen] = useState(false)
    let { id } = useParams();

    const changeAudioState = () => {
        api
        .changeAudioVideoState(authHelper.getUserId(), id, !audioWorks, videoWorks)
        .then(res => setAudioWorks(!audioWorks))
    }
    const changeVideoState = () => {
        api
        .changeAudioVideoState(authHelper.getUserId(), id, audioWorks, !videoWorks)
        .then(res => setVideoWorks(!videoWorks))
    }

    return (
        <div className='instrument-panel'>
            <div className='instrument-select-panel'>          
                <div className='instruments'>
                    {['selector', 'pen', 'eraser', 'marker', 'notes'
                    // 'shapes'
                    ].map(x=>
                    <div className='instrument'>
                        {x.toLowerCase() === instrument && <div className='active' />}
                        <div 
                        className={x + ' icon'}
                        onClick={()=>{
                            setInstrument(x.toLowerCase())
                            setElements(elements => {
                                return elements.map(x=> {
                                    x.selected = false 
                                    return x
                                } )
                            })   
                        }}/>
                    </div>)}
                </div>
                
                <div className='divider' />
                <div className='instruments'><div className='instrument'> <div className='clean icon' onClick={() => setConfirmBoardClearingModalOpen(true)}/> </div></div>
                <div className='divider' />

                <div className='instruments'>
                    {[
                        {
                            name: `audio ${audioWorks ? '': 'off'}`,
                            onClick: changeAudioState
                        },
                        {
                            name: `camera ${videoWorks ? '': 'off'}`,
                            onClick: changeVideoState
                        },
                        // {
                        //     name: `screen ${screenWorks ? '': 'off'}`,
                        //     onClick: () => {
                        //         setScreenWorks(!screenWorks)
                        //     }
                        // },
                        {
                            name: 'settings',
                            onClick: () => setInfoRoomModalOpen(true)
                        }
                    ].map(x=>
                    <div className='instrument'>
                        {x.name.toLowerCase() === instrument && <div className='active' />}
                        <div 
                        className={x.name + ' icon'}
                        onClick={x.onClick}/>
                    </div>)}
                </div>
            </div>

            {
                (instrument === 'pen' || instrument === 'marker') &&                
                <div className='colors'>
                    {
                        colors.map((color, index) => 
                            <div 
                            style={{background: color}} 
                            className={
                                `color 
                                ${(instrument === 'pen' && penColorIndex === index || 
                                    instrument === 'marker' && markerColorIndex === index) ? 'active': ''}
                                `} 
                            onClick={() => {
                                if (instrument === 'pen') setPenColorIndex(index)
                                else if (instrument === 'marker') setMarkerColorIndex(index)
                            }}    
                            />
                        )
                    }
                </div>
            }

            <ConfirmBoardClearingModal open={confirmBoardClearingModalOpen} setOpen={setConfirmBoardClearingModalOpen} roomId={id} setElements={setElements}/>
            <InfoRoomModal open={openInfoRoomModal} setOpen={setInfoRoomModalOpen} setUsers={setUsers}/>
        </div>
    )
}

export default InstrumentPanel