import { useContext, useEffect, useRef, useState } from 'react';
import './Chat.scss'
import api from '../../../../shared/api';
import authHelper from '../../../../utils/authHelper';
import toast from '../../../../utils/toast';
import WsContext from '../../../../WsContext';
import backHelper from '../../../../utils/backHelper';
import { useParams } from 'react-router-dom';

const Chat = () => {
    let { id } = useParams();
    const [messages, setMessages] = useState([])
    const [messageForSend, setMessageForSend] = useState('')
    const [attachFiles, setAttachFiles] = useState([])
    const refFileInput = useRef()
    const messagesElementRef = useRef()
    const wsMessage = useContext(WsContext)

    const load = () => {
        api
        .getMessages(id, authHelper.getUserId())
        .then(resp => {
            setMessages(resp)
            setTimeout(()=>{
                messagesElementRef.current.scrollTop = messagesElementRef.current.scrollHeight
            }, 100)
        })
    }
    useEffect(()=>{
        load()
    }, [])

    useEffect(() => {
        if(wsMessage?.type == 'new_message'){
            toast.success(`"${wsMessage.userName}": "${wsMessage.message}"`)
            
            load()
        }
    }, [wsMessage])

    const send = () => {
        api.sentMessage(id, authHelper.getUserId(), messageForSend)
        .then(resp => {
            setMessageForSend('')
            load()
        })
    }

    const onAttachFiles = (event)=>{
        let file = event.target.files[0]
        api
        .uploadFile(authHelper.getUserId(), id, file.name, file)
        .then(resp => {
            setAttachFiles(old => [...old, resp])
            event.target.value = null
        })
    }
    return (
        <div className='chat-container'>
            <div className='messages' onWheel={e => e.stopPropagation()} ref={messagesElementRef}>
                {messages.map(message => 
                <div style={{justifyContent: message.itsYou? 'end': 'start'}} className='message-container'>
                    <div className='message'>
                        <b>{message.user}</b> <br />
                        {message.text}
                        {message.files.map(file=><div className='file' onClick={()=>{window.open(`${backHelper()}api/file/download?id=${file.id}&fileName=${file.name}`, '_blank')}}>
                        <div className='name'>{file.name}</div>
                        <div className='size'>{file.size}</div>
                    </div>)}
                    </div>
                </div>)}
            </div>
            <div className='send-message-form'>
                <div className='attach-files'>
                    {attachFiles.map(x=><div className='file'>
                        <div className='name'>{x.fileName}</div>
                        <div className='size'>{x.size}</div>
                    </div>)}
                </div>
                <textarea 
                value={messageForSend}
                onChange={e => {setMessageForSend(e.target.value)}}
                onKeyDown={e=>{
                    if(e.key == 'Enter'){
                        send()
                    }
                }}
                /> 
                <div onClick={send} className='send icon' />
                <input ref={refFileInput} className='file icon' type='file' style={{ opacity: 0 }} onChange={onAttachFiles}/>
                <div className='file icon' onClick={()=>{refFileInput.current.click()}}/>
            </div>
        </div>
    )
}

export default Chat;