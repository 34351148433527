// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../public/icons/copy.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../../public/icons/copy-hover.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-button-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: end;
}
.name-button-container .custom-input {
  margin-right: 15px;
}

.copy {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
}
.copy:hover {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: contain;
}`, "",{"version":3,"sources":["webpack://./src/pages/BoardPage/components/InfoRoomModal/InfoRoomModal.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AACI;EACI,kBAAA;AACR;;AAGA;EACI,mDAAA;EACA,wBAAA;AAAJ;AAEI;EACI,mDAAA;EACA,wBAAA;AAAR","sourcesContent":[".name-button-container {\n    display: grid;\n    grid-template-columns: 2fr 1fr;\n    align-items: end;\n\n    .custom-input {\n        margin-right: 15px;\n    }\n}\n\n.copy {\n    background: url('../../../../../public/icons/copy.svg');\n    background-size: contain;\n\n    &:hover {\n        background: url('../../../../../public/icons/copy-hover.svg');\n        background-size: contain;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
