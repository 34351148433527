import './Cursor.scss'

const Cursor = ({instrument, instrumentState, currentMousePosition, cursorHeight, height, width, centerCoords}) => {
    return <div 
    className={`cursor-${instrument}${instrumentState == null ? '' : `-${instrumentState}`}`}
    style={{
        top: `${((currentMousePosition?.y-cursorHeight) + height/2 - centerCoords.y)}px`,
        left: `${((currentMousePosition?.x) + width/2 - centerCoords.x)}px`
    }}/>
}

export default Cursor