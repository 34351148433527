
import api from "../../../../../shared/api"
import authHelper from "../../../../../utils/authHelper"
import backHelper from "../../../../../utils/backHelper"
import getFontSize from "./getFontSize"
import jsonParse from "./jsonParse"
import SvgFromPoint from "./SvgFromPoint/SvgFromPoint"
import useDynamicState from "../../../../../shared/useStateDynamic"
import { noteColors } from "./colors"

const renderElement = (
    element,
    width,
    height,
    normalizedZoom,
    noteFontSize,
    centerCoords,
    penStrokeWidth,
    markerStrokeWidth,
    instrument,
    setElements,
    instrumentState
) => {
    let data = jsonParse(element.data)

    const handleChangeNoteText = (id, newText) => { 
        setElements(elements => {
            return elements.map(x=> {
                if(id === x.id){
                    data.text = newText
                    x.data = JSON.stringify(data)

                    api
                    .updateBoardElement( 
                        id, 
                        JSON.stringify(data), 
                        authHelper.getUserId())
                    .then(res => {})
                }
                return x
            } )
        })    
    }; 

    const movableElementStyle = {
        top: data.y * normalizedZoom + height/2 + centerCoords.y,
        left: data.x * normalizedZoom + width/2 + centerCoords.x,
        width: data.width * normalizedZoom,
        height: data.height * normalizedZoom,
    }

    const blueCircle = () => {
        let thresholdTop = 13
        let thresholdLeft = 13

        if (element.type === 3) {
            thresholdLeft = 0.1 * data.width < 40 && 0.1 * data.width > 15? 0.1 * data.width: 0.1 * data.width > 50? 50: 15
            if (data.degree < 0) {
                thresholdTop += 5
            }
        }

        return element.selected && 
            <div 
            className='circle' 
            style={{
                backgroundColor: instrumentState === 'resize'? '#7dd3fc': instrumentState === 'move'? '#0ea5e9': '#0369A1',
                top: (data.y + data.height) * normalizedZoom + height/2 - thresholdTop + centerCoords.y,
                left: (data.x + data.width) * normalizedZoom + width/2 - thresholdLeft + centerCoords.x,
            }}
            />
    }

    switch(element.type) {
        case 0:
            return <SvgFromPoint
            windowSize={{width, height}}
            zoom={normalizedZoom}
            centerCoords={centerCoords}
            strokeWidth={penStrokeWidth}
            instrument='pen'
            colorIndex={data.color}
            pointsData={jsonParse(data.curve)} />
        case 1:
            return <div>
                <img
                className={`element image ${element.hover? 'hover': element.selected? 'selected': ''}`}
                style={movableElementStyle}
                alt=''
                src={`${backHelper()}api/image/get?id=${element.id}`}
                />
                {blueCircle()}
            </div>
        case 2:
            return <SvgFromPoint
            windowSize={{width, height}} 
            zoom={normalizedZoom}
            centerCoords={centerCoords}
            colorIndex={data.color}
            instrument='marker'
            opacity={0.5}
            strokeWidth={markerStrokeWidth} 
            pointsData={jsonParse(data.curve)} />
        case 3:
            return <div>
                <div 
                style={movableElementStyle}
                className="note-container">
                    <div 
                    style={{
                        background: data.color? noteColors[data.color]: noteColors[0],
                        fontSize: `${noteFontSize}`,
                        transform: data.degree? `rotate(${data.degree}deg)`: 'rotate(3deg)'
                    }}
                    className={`element note ${element.hover? 'hover': element.selected? 'selected': ''}`} >
                        <div 
                        style={{
                            transform: data.degree? `rotate(${-data.degree}deg)`: 'rotate(-3deg)'
                        }}
                        className="text">{data.text}</div>
                    </div>
                    <div className="note-sticker" />
                    {element.selected &&
                    instrument === 'notes' && 
                    <textarea
                    value={data.text} 
                    autoFocus
                    onChange={e => handleChangeNoteText(element.id, e.target.value)} 
                    />
                    }
                </div>
                {blueCircle()}
            </div>
        default:
            return null
    }
}

export default renderElement