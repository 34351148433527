
const authHelper = () => {
    return { 
        getUserId: () => {
            return localStorage.getItem('tutorConnectionUserId')
        },
        setUserId: (id) => {
            return localStorage.setItem('tutorConnectionUserId', id)
        },
    }
}

export default authHelper()