import CopyToClipboard from 'react-copy-to-clipboard';
import './CustomInput.scss'
import toast from '../../utils/toast';

const CustomInput = ({
    label, 
    disabled=false, 
    type='text', 
    placeholder,
    value, 
    setValue,
    error=false,
    setError=()=>{},
    onFocus=()=>{},
    onFocusLeave=()=>{},
    inputRef=null,
    onKeyDown=()=>{},
    autoFocus=false,
    style=null,
    icon=null,
    onIconClick=()=>{},
    copy=false
}) => {
    return (
        <div className='custom-input-container' style={style}>
            <p style={{marginBottom: '15px'}}>{label}</p>
            <input 
            ref={inputRef}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={onFocusLeave}
            autoFocus={autoFocus}
            value={value ?? ''}
            onKeyDown={onKeyDown}
            type={type}
            onFocus={()=>{onFocus(); setError(false)}}
            onChange={(e) => setValue(e.target.value)}
            style={{paddingRight: icon ? '50px': '30px'}}
            className={`custom-input ${error ? 'error': ''}`} />
            
            {
                copy ?
                <CopyToClipboard
                onCopy={()=>{toast.success('Скопировано в буфер обмена!')}} 
                text={window.location.href}>
                    <div 
                    onClick={onIconClick}
                    className='icon-container'>
                        {icon}
                    </div>
                </CopyToClipboard>:
                <div 
                onClick={onIconClick}
                className='icon-container'>
                    {icon}
                </div>
            }

        </div>
    )
}

export default CustomInput