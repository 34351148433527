import api from "../../../../../shared/api"
import authHelper from "../../../../../utils/authHelper"
import jsonParse from "./jsonParse"
import normalizeZoom from "./normalizeZoom"
import pointInRectangle from "./pointInRectangle"

const mainLoop = (
    roomId,
    cursorHeight,
    resizeThreshold,

    elementsInit, 
    currentMousePosition, 
    zoom, 
    clicked,
    instrument,
    isLoading,
    startMovebleElementCoords,
    startMovePoint,
    startResizingElementSize,
    curveState,

    setCurveState,
    setIsLoading,
    setElements,
    setInstrumentState,

    penColor,
    markerColor,

    centerCoords,
    setCenterCoords
) => {
    let elements = elementsInit.map(x => {return {...x, data: jsonParse(x.data)}})
    let current = {
        x: currentMousePosition.x - 2*centerCoords.x,
        y: currentMousePosition.y - 2*centerCoords.y,
    }
    let normalizedZoom = normalizeZoom(zoom)

    if(clicked){
        if (instrument == 'pen' || instrument == 'marker') {
            setCurveState(c => {
                let temp = c.map(x=>x)
                let newVar = {
                    x: current.x / normalizedZoom,
                    y: current.y / normalizedZoom
                };
                temp.push(newVar)
                return temp
            })
        }
        else if (instrument === 'eraser') {
            let eraserSize = 30
            let elementsForDelete = elements
                                    .filter(el => {
                                        if (el.type === 0 || el.type === 2) {
                                                if (jsonParse(el.data.curve).filter(point => 
                                                    pointInRectangle(
                                                        {x: point.x, y: point.y},
                                                        {
                                                            x: current.x/normalizedZoom,
                                                            y: current.y/normalizedZoom,
                                                            width: eraserSize,
                                                            height: eraserSize,
                                                        },
                                                        normalizedZoom,
                                                        false
                                                    )
                                                ).length > 0) return true
                                        } else if (el.type === 1 || el.type === 3) {
                                            if (pointInRectangle(
                                                {x: current.x, y: current.y},
                                                {
                                                    x: el.data.x,
                                                    y: el.data.y,
                                                    width: el.data.width,
                                                    height: el.data.height,
                                                },
                                                normalizedZoom
                                            )) return true
                                        }
                                        return false
                                    }).map(x=>x.id)
                
            if(elementsForDelete.length > 0 && !isLoading){
                setIsLoading(true)
                api
                .deleteBoardElements(authHelper.getUserId(), roomId, elementsForDelete)
                .then(resp => {
                    setIsLoading(false)
                    setElements(elements => {
                        let temp = elements?.map(x=>x)
                        temp = temp.filter(x=> !elementsForDelete.includes(x.id))
                        return temp
                    })
                })    
            }
        }
    }
    if(!clicked && curveState.length > 0 && !isLoading){
        setIsLoading(true)
        let type = 0 
        switch(instrument) {
            case 'pen': 
                type = 0 
                break
            case 'marker': 
                type = 2 
                break
            default:
                return null
        }
        api
        .createBoardElement(authHelper.getUserId(), roomId, type, {
            curve: JSON.stringify(curveState), 
            color: instrument === 'pen'? penColor: markerColor
        })
        .then(elementId => {
            setElements(elements => {
                let temp = elements?.map(x=>x)
                temp.push({id: elementId, type: type, 
                    data: JSON.stringify(
                        {
                            curve: JSON.stringify(curveState), 
                            color: instrument === 'pen'? penColor: markerColor
                        })})
                return temp
            })
            setCurveState([])
            setIsLoading(false)
        })
    }

    if (instrument === 'selector' || instrument === 'notes') {
        const currentY = current.y - cursorHeight
        let el_id = null
        let data = null
        let newInstrumentState = null
        
        elements?.forEach(el => {
            if (pointInRectangle(
                {x: current.x, y: currentY}, 
                {x: el.data.x, y: el.data.y, width: el.data.width, height: el.data.height}, 
                normalizedZoom)) {
                    el_id = el.id
                    if (instrument === 'selector') {
                        newInstrumentState = 'scalable'
                    }
                    else if (instrument === 'notes' && el.type === 3) newInstrumentState = 'text'  
                }

            if((pointInRectangle(
                {x: current.x, y: currentY}, 
                {x: el.data.x + el.data.width - resizeThreshold/2, y: el.data.y + el.data.height - resizeThreshold/2, width: resizeThreshold, height: resizeThreshold}, 
                normalizedZoom)))
                {
                    newInstrumentState = 'resize'
                }

            if (el.selected) {
                el_id = el.id 
                data = el.data
            }
        })

        if((startMovePoint != null || startResizingElementSize != null) && data != null && el_id != null) {
            let diffX = (startMovePoint.x - current.x) / normalizedZoom
            let diffY = (startMovePoint.y - current.y) / normalizedZoom
            if(startMovebleElementCoords != null && startResizingElementSize == null) {
                data.x = startMovebleElementCoords.x - diffX
                data.y = startMovebleElementCoords.y - diffY
                newInstrumentState = 'move'
            }

            if(newInstrumentState === 'resize' || startResizingElementSize != null) {
                data.width = startResizingElementSize.width - diffX
                data.height = startResizingElementSize.height - diffY
            }
        }

        if (newInstrumentState !== null)
            setElements(elements => {return elements.map(elItem => {
                return {   
                    ...elItem,
                    data: elItem.id === el_id && elItem.selected && data != null ? JSON.stringify(data): elItem.data,
                    hover: elItem.id === el_id && !elItem.selected ? true : false
                };
            })});
        else if (startMovePoint !== null) {
            newInstrumentState = 'move'
            
            let diffX = (startMovePoint.x - current.x)
            let diffY = (startMovePoint.y - current.y)
            
            diffX = normalizedZoom > 1? diffX / normalizedZoom: diffX * normalizedZoom
            diffY = normalizedZoom > 1? diffY / normalizedZoom: diffY * normalizedZoom
            
            setCenterCoords({
                x: centerCoords.x - diffX,
                y: centerCoords.y - diffY
            })
        }

        setInstrumentState(newInstrumentState)
    }
}

export default mainLoop;