// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.custom-input-container .icon-container {
  position: absolute;
  right: 15px;
  bottom: 0;
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/shared/CustomInput/CustomInput.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACR","sourcesContent":[".custom-input-container {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    \n    .icon-container {\n        position: absolute;\n        right: 15px;\n        bottom: 0;\n        height: 42px;\n        display: flex;\n        align-items: center;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
