import colors from '../colors';
import jsonParse from '../jsonParse';
import './SvgFromPoint.scss'


const BezierCurve = ({ points, colorIndex=0, opacity, strokeWidth=2 }) => {
    const d = points.reduce((acc, point, index) => {
        if (index === 0) {
            return `M ${point.x} ${point.y}`;
        }

        const prevPoint = points[index - 1];

        const x1 = (2 * point.x + prevPoint.x) / 3;
        const y1 = (2 * point.y + prevPoint.y) / 3;
        const x2 = (point.x + 2 * prevPoint.x) / 3;
        const y2 = (point.y + 2 * prevPoint.y) / 3;

        return `${acc} C ${x1} ${y1}, ${x2} ${y2}, ${point.x} ${point.y}`;
    }, '');

    return <path d={d} stroke={colors[colorIndex]} opacity={opacity} fill="none" strokeWidth={strokeWidth} strokeLinecap="round"/>;
};

const SvgFromPoint = ({ pointsData, colorIndex=0, strokeWidth=2, opacity=1, windowSize, zoom, centerCoords}) => {
    if(pointsData.length == 0) return ''
    return <svg 
    style={{
        position: 'absolute', 
        top: `${(pointsData[0].y) * zoom   + windowSize.height/2 + centerCoords.y}px`, 
        left: `${(pointsData[0].x)* zoom + windowSize.width/2 + centerCoords.x}px`,
        overflow: 'visible'
    }}>
        <BezierCurve colorIndex={colorIndex} opacity={opacity} strokeWidth={strokeWidth} points={pointsData.map(z=> {return {x: (z.x - pointsData[0].x) * zoom, y: (z.y - pointsData[0].y) * zoom}})} />
    </svg>
};

export default SvgFromPoint;