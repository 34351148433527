const getFontSize = (zoom) => {  
    let fontSize;

    switch (zoom) {  
        case 1:  
            fontSize = '8px';  
            break;  
        case 2:  
            fontSize = '10px';  
            break;  
        case 3:  
            fontSize = '12px';  
            break;  
        case 4:  
            fontSize = '14px';  
            break;  
        case 5:  
            fontSize = '16px';  
            break;  
        case 6:  
            fontSize = '18px';  
            break;  
        case 7:  
            fontSize = '20px';  
            break;  
        case 8:  
            fontSize = '21px';  
            break;  
        case 9:  
            fontSize = '23px';  
            break;  
        case 10:  
            fontSize = '25px';
            break;  
        default:  
            fontSize = '16px';
    }  

    return fontSize;  
}  

export default getFontSize