import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import StartPage from './pages/StartPage/StartPage';
import BoardPage from './pages/BoardPage/BoardPage';
import React, { useEffect, useState } from 'react';
import { uuidv4 } from './utils/uuidv4';
import ToastContainer from './shared/ToastContainer/ToastContainer';
import api from './shared/api';
import authHelper from './utils/authHelper';
import toast from './utils/toast';
import WsContext from './WsContext';
import backHelper from './utils/backHelper';
import jsonParse from './pages/BoardPage/components/WhiteBoard/components/jsonParse';


function App() {
  const [isInit, setIsInit] = useState(authHelper.getUserId() != undefined);
  const [messageFromWs, setMessageFromWs] = useState(null)

  const connectWs = (id) => {
    let host = backHelper(true)
    let wsHostType = window.location.hostname == 'localhost' ? 'ws' : 'wss'
    var ws = new WebSocket(`${wsHostType}://${host}/api/ws/${id}`);
    window.ws = ws
    ws.onmessage = function(event) {
      let data = jsonParse(event.data)
      setMessageFromWs(data)
    };
  }

  useEffect(() => {
    if(authHelper.getUserId() == undefined){
      api
      .createUser()
      .then(resp => {
        authHelper.setUserId(resp.id)
        connectWs(resp.id)
        setIsInit(true)
      })
    }else{
      connectWs(authHelper.getUserId())
    }
  }, [])
  const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <StartPage />,
      // errorElement: <ErrorPage />
    },
    {
      path: "/:id",
      element: <BoardPage />,
      // errorElement: <ErrorPage />
    },
  ])
  if(!isInit)
    return ''

  return (<>
    <WsContext.Provider 
    value={messageFromWs}>
      <RouterProvider router={router} /> 
    </WsContext.Provider>
    <ToastContainer />
  </>
  );
}

export default App;
