import jsonParse from "./jsonParse"
import pointInRectangle from "./pointInRectangle"

const onWhiteBoardMouseDown = (
    e,
    width,
    height,
    centerCoords,
    normalizedZoom,
    resizeThreshold,

    elements,

    setClicked,
    setStartMovePoint,
    setStartMovebleElementCoords,
    setStartResizingElementSize,
    setSelectedElementId,

    cursorHeight
) => {
    let x, y;
    if (e.type === 'touchmove') {
        const touch = e.touches[0];
        x = touch.clientX;
        y = touch.clientY;
    } else {
        x = e.clientX;
        y = e.clientY;
    }
    let point = {x: x - width/2 - centerCoords.x, y: y - height/2 - centerCoords.y}
    
    if (e.button === 1) {
        setStartMovePoint(point)
        return
    }

    setClicked(true)
    let filteredElementsForMove = elements.filter(el => {
        let data = jsonParse(el.data)
        return (el.type === 1 || el.type === 3) && el.selected &&  pointInRectangle(
            point,
            {x: data.x, y: data.y, width: data.width, height: data.height + cursorHeight + 5}, normalizedZoom)})
 
    let filteredElementsForResize = elements.filter(el => {
        let data = jsonParse(el.data)
        return (el.type === 1 || el.type === 3) && el.selected &&  pointInRectangle(
            point, 
            {x: data.x + data.width - resizeThreshold/2, y: data.y + data.height - resizeThreshold/2, width: resizeThreshold, height: resizeThreshold}, normalizedZoom)}) 

    if (filteredElementsForMove.length > 0 || filteredElementsForResize.length > 0) {
        setStartMovePoint(point)
        if(filteredElementsForMove.length > 0) {
            let element = filteredElementsForMove[0]
            setSelectedElementId(element.id)
            let data = jsonParse(element.data)
            setStartMovebleElementCoords({x: data.x, y: data.y})    
        }
        if(filteredElementsForResize.length > 0) {
            let element = filteredElementsForResize[0]
            setSelectedElementId(element.id)
            let data = jsonParse(element.data)
            setStartResizingElementSize({width: data.width, height: data.height})
        }
    } else {
        setStartMovePoint(null)
        setStartMovebleElementCoords(null)
    }
}

export default onWhiteBoardMouseDown;