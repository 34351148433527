import { useNavigate } from 'react-router-dom';
import './StartPage.scss'
import api from '../../shared/api'; 

const StartPage = () => {
    const navigate = useNavigate()
    const start = () => {
        api
        .createRoom(localStorage.getItem('tutorConnectionUserId'))
        .then(resp=>{
            navigate(`/${resp.id}`)
        })
    }
    return (
        <div className='start-page'>
            <div className='modal-screen' />
            <div className='welcome-message'>
                <div className='logo' />
                <h1>Beresta</h1>
                <p>Бесплатный сервис для проведения занятий между преподавателем и учениками.</p>
                <p>Создайте урок и получите уникальную ссылку, которую можно отправить ученикам.</p>
                <button onClick={start}>Создать встречу</button>
                <a 
                style={{marginTop: '15px'}}
                href='mailto:info@beresta.link'>info@beresta.link</a>
            </div>
        </div>
    )
}

export default StartPage;