import { useContext, useEffect } from 'react';
import './WhiteBoard.scss'
import api from '../../../../shared/api';
import authHelper from '../../../../utils/authHelper';
import WsContext from '../../../../WsContext';
import useDynamicState from '../../../../shared/useStateDynamic';
import { useWindowSize } from '../../../../utils/windowSize';
import SvgFromPoint from './components/SvgFromPoint/SvgFromPoint';
import toast from '../../../../utils/toast';
import InstrumentPanel from './components/InstrumentPanel/InstrumentPanel';
import pointInRectangle from './components/pointInRectangle';
import jsonParse from './components/jsonParse';
import renderElement from './components/renderElement';
import normalizeZoom from './components/normalizeZoom';
import Cursor from './components/Cursor/Cursor';
import mainLoop from './components/mainLoop';
import onWhiteBoardMouseDown from './components/onWhiteBoardMouseDown';
import colors from './components/colors';
import getFontSize from './components/getFontSize';

const WhiteBoard = ({roomId, membersMouseCoords, onMouseCoordsChange, users, setUsers}) => {
    const [clicked, setClicked, clickedRef] = useDynamicState(false)
    const [elements, setElements, elementsRef] = useDynamicState([])
    const [curveState, setCurveState, curveStateRef] = useDynamicState([])
    const [currentMousePosition, setCurrentMousePosition, currentMousePositionRef] = useDynamicState({x: 0, y: 0})
    const [instrument, setInstrument, instrumentRef] = useDynamicState('pen')
    const [instrumentState, setInstrumentState, instrumentStateRef] = useDynamicState(null)
    const [isLoading, setIsLoading, isLoadingRef] = useDynamicState(false)
    const [startMovePoint, setStartMovePoint, startMovePointRef] = useDynamicState(null)
    const [startMovebleElementCoords, setStartMovebleElementCoords, startMovebleElementCoordsRef] = useDynamicState(null)
    const [startResizingElementSize, setStartResizingElementSize, startResizingElementSizeRef] = useDynamicState(null)
    const [zoom, setZoom, zoomRef] = useDynamicState(5)
    const [selectedElementId, setSelectedElementId, selectedElementIdRef] = useDynamicState(null)
    const [centerCoords, setCenterCoords, centerCoordsRef] = useDynamicState({x: 0, y: 0})
    const [noteFontSize, setNoteFontSize, noteFontSizeRef] = useDynamicState('16px')
    const [width, height] = useWindowSize();

    let normalizedZoom = normalizeZoom(zoomRef.current)

    const wsMessage = useContext(WsContext)
    const penStrokeWidth= 2 * (zoom * 0.25)
    const markerStrokeWidth= 15 * (zoom * 0.25)
    const cursorHeight = 30
    const resizeThreshold = 50

    const [penColorIndex, setPenColorIndex, penColorIndexRef] = useDynamicState(0)
    const [markerColorIndex, setMarkerColorIndex, markerColorIndexRef] = useDynamicState(3)

    useEffect(() => setNoteFontSize(getFontSize(zoomRef.current)), [zoom])

    useEffect(() => { 
        if(wsMessage?.type === 'add_element'){
            if(wsMessage.roomId === roomId){
                setElements(elements => {
                    let temp = elements?.map(x=>x)
                    temp.push({id: wsMessage.id, type: wsMessage.elementType, data: wsMessage.data })
                    return temp
                })
                setCurveState([])
            }
        }
        if(wsMessage?.type === 'remove_element'){
            if(wsMessage.roomId === roomId){
                setElements(elements => {
                    return elements.filter(x=> !wsMessage.ids.includes(x.id) )
                })                
            }
        }
        if(wsMessage?.type === 'clear'){
            if(wsMessage.roomId === roomId){
                setElements([])                
            }
        }
        if(wsMessage?.type === 'update_element'){
            if(wsMessage.roomId === roomId){
                setElements(elements => {
                    return elements.map(x=> {
                        if(wsMessage.id === x.id){
                            x.data = wsMessage.data
                        }
                        return x
                    } )
                })                
            }
        }
        if(wsMessage?.type === 'change_elements_order'){
            if(wsMessage.roomId === roomId){
                setElements(els => {
                    let target = els.filter(x=> x.id === wsMessage.elId)[0]
                    let temp = els.filter(x=> x.id !== wsMessage.elId)
                    temp.push(target)
                    return temp
                })               
            }
        }
    }, [wsMessage])

    useEffect(() => {
        const intervalId = setInterval(() => mainLoop(
                                                roomId,
                                                cursorHeight,
                                                resizeThreshold,
                                                elementsRef.current,
                                                currentMousePositionRef.current, 
                                                zoomRef.current, 
                                                clickedRef.current,
                                                instrumentRef.current,
                                                isLoadingRef.current,
                                                startMovebleElementCoordsRef.current,
                                                startMovePointRef.current,
                                                startResizingElementSizeRef.current,
                                                curveStateRef.current,
                                                setCurveState,
                                                setIsLoading,
                                                setElements,
                                                setInstrumentState,
                                                penColorIndexRef.current,
                                                markerColorIndexRef.current,
                                                centerCoordsRef.current, 
                                                setCenterCoords
                                            ), 1)

        api
        .getBoardElementList(roomId)
        .then(resp=>{
            setElements(resp)
        })
        const preventZoom = (event) => {
            if (event.deltaY) {
                event.preventDefault();
                let newZoom = zoomRef.current
                if(event.deltaY > 0){
                    if(zoomRef.current > 1)
                        newZoom -= 1
                }
                else{
                    if(zoomRef.current < 10)
                        newZoom += 1
                }
                setZoom(newZoom)
            }
        };
    
        window.addEventListener('wheel', preventZoom, { passive: false })
      
        return () => {
            clearInterval(intervalId);
            window.removeEventListener('wheel', preventZoom);
        }
    }, [])

    const handlePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (let index in items) {
            let item = items[index];
            if (item.kind === 'file') {
                let imageFile = item.getAsFile();
                let fileName = imageFile.name
                api
                .uploadFile(authHelper.getUserId(), roomId, fileName, imageFile, 
                    (currentMousePositionRef.current.x - 2*centerCoordsRef.current.x) / normalizedZoom,
                    (currentMousePositionRef.current.y - 2*centerCoordsRef.current.y) / normalizedZoom)
                .then(resp => {
                    if (resp.isImage) {
                        setElements([...elementsRef.current, {
                            id: resp.id,
                            type: 1,
                            data: JSON.stringify({
                                x: (currentMousePositionRef.current.x - 2*centerCoordsRef.current.x) / normalizedZoom, 
                                y: (currentMousePositionRef.current.y - 2*centerCoordsRef.current.y) / normalizedZoom, 
                                width: resp.width, height: resp.height})
                        }])    
                    } else {
                        toast.success(`${resp.fileName} отправлен в чат`)
                    }
                })
            }
        }
    };

    const onClickWhiteBoard = (e) => {
        if (instrumentRef.current === 'selector' || instrumentRef.current === 'notes') {
            let el_id = null
            let x, y;
            if (e.type === 'touchmove') {
                const touch = e.touches[0];
                x = touch.clientX - 2*centerCoords.x;
                y = touch.clientY - cursorHeight - 2*centerCoords.y;
            } else {
                x = e.clientX - 2*centerCoords.x;
                y = e.clientY - cursorHeight - 2*centerCoords.y;
            }
            x = x - width/2 + centerCoordsRef.current.x
            y = y - height/2 + centerCoordsRef.current.y
            elementsRef.current.filter(el => {
                if(el.type !== 1 && el.type !== 3) return false;
                let data = jsonParse(el.data)
                if(pointInRectangle({x, y}, {x: data.x, y: data.y, width: data.width, height: data.height}, normalizedZoom) || startResizingElementSizeRef.current)
                    el_id = el.id 
            })

            if (instrumentRef.current === 'notes' && instrumentStateRef.current !== 'text' && elementsRef.current.filter(el => el.selected).length === 0) {
                const data = {
                    x: (currentMousePositionRef.current.x - 2*centerCoords.x) / normalizedZoom,
                    y: (currentMousePositionRef.current.y  - 2*centerCoords.y) / normalizedZoom, 
                    width: 100,
                    height: 100,
                    text: ''
                }
                api
                .createBoardElement(authHelper.getUserId(), roomId, 3, data)
                .then(res => {
                    setElements(elements => {
                        let temp = elements?.map(x=>x)
                        temp.push({id: res, type: 3, data: JSON.stringify(data)})
                        return temp
                    })
                })
            }
            setElements(els => {
                let target = els.filter(x=> x.id === el_id)[0]
                if(target == undefined)
                    return els.map(x=>{ return {...x, selected: false}})
                let temp = els.filter(x=> x.id !== el_id)
                temp = temp.map(x=> {return {...x, selected: false}})
                temp.push({...target, selected: instrumentRef.current === 'selector' || target.type === 3})

                api
                .changeElementsOrder(authHelper.getUserId(), el_id)
                .then(res => {})
                return temp
            })
            setStartResizingElementSize(null)
        }
    }

    const onMove = e => {
        e.preventDefault()
        let x, y;
        if (e.type === 'touchmove') {
            const touch = e.touches[0];
            x = touch.clientX;
            y = touch.clientY;
        } else {
            x = e.clientX;
            y = e.clientY;
        }
        x = x - width/2 + centerCoordsRef.current.x
        y = y - height/2 + centerCoordsRef.current.y
        setCurrentMousePosition({x: x, y: y})
        onMouseCoordsChange({
            x: x / normalizedZoom + centerCoordsRef.current.x, 
            y: y / normalizedZoom + centerCoordsRef.current.y
        }, clicked)
    }

    const onDown = e => onWhiteBoardMouseDown (
        e,
        width,
        height,
        centerCoordsRef.current,
        normalizedZoom,
        resizeThreshold/normalizedZoom,
        elementsRef.current,
        setClicked,
        setStartMovePoint,
        setStartMovebleElementCoords,
        setStartResizingElementSize,
        setSelectedElementId,
        cursorHeight
    )

    const onUp = (e) => {
        e.preventDefault()
        setClicked(false)
        setStartMovePoint(null)
        if (selectedElementIdRef.current != null)
            api
            .updateBoardElement( 
                selectedElementIdRef.current, 
                elementsRef.current.filter(el => el.id === selectedElementIdRef.current)[0]?.data, 
                authHelper.getUserId())
            .then(res => {})
    }
    
    return (<>
        <div 
        onPaste={handlePaste} 
        className='whiteboard' 
        style={{
            width: `${width}px`, 
            height: `${height}px`, 
            backgroundSize: `${(normalizedZoom) * 200}px`,
            backgroundPosition: `top ${centerCoordsRef.current.y}px left ${centerCoordsRef.current.x}px`
        }}>
        {/* <div style={{
            top: height/2,
            left: width/2,
            height: '10px',
            width: '10px',
            background: 'red',
            borderRadius: '50%',
            position: 'absolute'
        }}/> */}
            {elements?.map(element => 
                renderElement(
                    element,
                    width,
                    height,
                    normalizedZoom,
                    noteFontSizeRef.current,
                    centerCoordsRef.current,
                    penStrokeWidth,
                    markerStrokeWidth,
                    instrumentRef.current,
                    setElements,
                    instrumentStateRef.current
                ))
            }
            {membersMouseCoords.map(membersMouseCoord => <div style={{
                position: 'absolute', 
                top: membersMouseCoord.y * normalizedZoom + height/2,
                left: membersMouseCoord.x * normalizedZoom + width/2,
                opacity: 0.5
                }}>
                <div style={{border: '1px solid black', height: '10px', width: '10px', backgroundColor: membersMouseCoord.clicked ? 'black' : 'white'}}></div>
                <div style={{}}>{membersMouseCoord.name}</div>
            </div>)}
            {curveState != undefined && curveState.length > 0 ? <SvgFromPoint 
            windowSize={{width, height}}
            zoom={normalizedZoom}
            centerCoords={centerCoordsRef.current}
            opacity={0.5}
            color={instrumentRef.current == 'pen' ? 'gray' : colors[markerColorIndexRef.current]} 
            strokeWidth={instrumentRef.current == 'pen' ? penStrokeWidth : markerStrokeWidth} 
            pointsData={curveState} /> : ''}
            <Cursor 
            instrument={instrument} 
            instrumentState={instrumentState} 
            centerCoords={centerCoordsRef.current}
            currentMousePosition={currentMousePositionRef.current} 
            cursorHeight={cursorHeight} 
            height={height} 
            width={width}/>
            <div 
            className='events-div'
            style={{
                width: `${width}px`, 
                height: `${height}px`, 
                position: 'absolute', 
            }}
            onMouseDown={onDown}
            onMouseUp={onUp}
            onClick={onClickWhiteBoard}
            onMouseMove={onMove}
            onTouchStart={onDown}
            onTouchEnd={onUp}
            onTouchMove={onMove}
            onKeyDown={e => {
                let stepForCoords = e.shiftKey ? 50 : 10;
                switch (e.key) {  
                    case 'ArrowUp':  
                        setCenterCoords({x: centerCoordsRef.current.x, y: centerCoordsRef.current.y + stepForCoords})
                        setCurrentMousePosition({
                            x: currentMousePositionRef.current.x,
                            y: currentMousePositionRef.current.y + stepForCoords,
                        })
                        break;  
                    case 'ArrowDown':  
                        setCenterCoords({x: centerCoordsRef.current.x, y: centerCoordsRef.current.y - stepForCoords})
                        setCurrentMousePosition({
                            x: currentMousePositionRef.current.x,
                            y: currentMousePositionRef.current.y - stepForCoords,
                        })
                        break;  
                    case 'ArrowLeft':  
                        setCenterCoords({x: centerCoordsRef.current.x + stepForCoords, y: centerCoordsRef.current.y})
                        setCurrentMousePosition({
                            x: currentMousePositionRef.current.x + stepForCoords,
                            y: currentMousePositionRef.current.y,
                        })
                        break;  
                    case 'ArrowRight':  
                        setCenterCoords({x: centerCoordsRef.current.x - stepForCoords, y: centerCoordsRef.current.y}) 
                        setCurrentMousePosition({
                            x: currentMousePositionRef.current.x - stepForCoords,
                            y: currentMousePositionRef.current.y,
                        })
                        break;  
                    case 'Delete':
                        let elementIdsForDelete = elementsRef.current.filter(el => el.selected).map(el => el.id)
                        api
                        .deleteBoardElements(authHelper.getUserId(), roomId, elementIdsForDelete)
                        .then(res => {
                            setElements(elements => {
                                let temp = elements?.map(x=>x)
                                temp = temp.filter(x=> !elementIdsForDelete.includes(x.id))
                                return temp
                            })
                        })
                        break;
                    default:  
                        break; 
            }}}
            tabIndex="0"
            />
            {
                users.length > 0 && 
                <InstrumentPanel 
                initAudioState={users.filter(u => u.id === authHelper.getUserId())[0].audio}
                initVideoState={users.filter(u => u.id === authHelper.getUserId())[0].video}
                instrument={instrument} 
                setInstrument={setInstrument}             
                setElements={setElements} 
                penColorIndex={penColorIndexRef.current} 
                setPenColorIndex={setPenColorIndex}
                markerColorIndex={markerColorIndexRef.current}
                setMarkerColorIndex={setMarkerColorIndex}
                setUsers={setUsers}
                />
            }
        </div>
    </>)
}

export default WhiteBoard;